
import Alpine from 'alpinejs';
import focus from '@alpinejs/focus'
import collapse from '@alpinejs/collapse'
import 'ken-burns-carousel';

window.Alpine = Alpine

Alpine.plugin(focus);
Alpine.plugin(collapse)

// start loading Components
import * as siteComponents from './Components';

const components = {};

for (let componentKey in siteComponents) {
  components[componentKey] = siteComponents[componentKey];
}

const run = () => {
  const componentNames = Object.keys(components);
  for (let i = 0; i < componentNames.length; i++) {
    const componentName = componentNames[i];

    // Find all instances of the component
    const elements = document.querySelectorAll(`[data-component="${componentName}"]`);
    for (let j = 0; j < elements.length; j++) {
      const element = elements[j];
      if (element.dataset.initialized === 'true') {
        continue;
      }
      element.dataset.initialized = 'true';
      // Initialise component on element
      new components[componentName](element);
    }
  }
}

setTimeout(() => run(), 0);
document.addEventListener('Neos.PageLoaded', run);
// end loading Components

if (document.documentElement.dataset.debug != undefined) {
  window.Alpine = Alpine;
}


Alpine.start()


